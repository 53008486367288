const prayers = {
        "college-prayer": {
                name: "St Leo’s Catholic College Prayer",
                content: `God of love and understanding,
You are the source of strength and inspiration.

You called Pope Saint Leo the Great
to guide your people by his word and example.

We ask you to fill our community with the spirit of respect and dignity.
Guide us in the light of your truth.

May we grow in purpose and learning, And proclaim 
your wisdom to the world

Give us courage and hope to face the unknown.
May we be faithful to God’s law in our hearts
And live with the peace of the Holy Spirit.

God help us to bear witness To the mystery of the cross and resurrection
So we may be people of integrity and justice, Walking in the footsteps of Christ your son.

Amen`,
                richContent: `
<p>God of love and understanding,<br>
You are the source of strength and inspiration.</p>

<p>You called Pope Saint Leo the Great<br>
to guide your people by his word and example.</p> 

<p>We ask you to fill our community with the spirit of respect and dignity.<br>
Guide us in the light of your truth.</p>

<p>May we grow in purpose and learning, And proclaim 
your wisdom to the world.</p>

<p>Give us courage and hope to face the unknown.<br>
May we be faithful to God’s law in our hearts<br>
And live with the peace of the Holy Spirit.</p>

<p>God help us to bear witness<br>
To the mystery of the cross and resurrection<br>
So we may be people of integrity and justice, Walking in the footsteps of Christ your son.</p>

<p><em>Amen</em></p>`,
        },
        "test-prayer": {
                name: "test",
                content: "Amen",
                richContent: "<b>Amen!</b>",
        },
};

export default prayers;
