import WidgetContainer from "components/widgets/WidgetContainer";
import HouseWidget from "components/widgets/HouseWidget";

export default function Houses() {
        return (
                <>
                        <h2>Houses</h2>

                        <WidgetContainer height="25em" width="100%">
                                <HouseWidget mode="list" animations="off" />
                        </WidgetContainer>
                </>
        );
}
