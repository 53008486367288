import styles from "./styles.module.css";

export default function WidgetContainer({
        children,
        height = "15em",
        width = "auto",
}) {
        return (
                <div
                        className={`widget-container ${styles.main}`}
                        style={{
                                height,
                                width,
                        }}
                >
                        {children}
                </div>
        );
}
