import { useParams } from "react-router-dom";
import houses from "lib/data/houses";

export default function House() {
        let params = useParams();
        return (
                <>
                        <h2>House {params.houseId} (todo: render data)</h2>
                </>
        );
}
