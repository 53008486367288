import styles from "./styles.module.css";
import { Link, useLocation } from "react-router-dom";

const links = [
        {
                id: "home",
                name: "Home",
                target: "/home",
        },
        {
                id: "prayers",
                name: "Prayers",
                target: "/prayers",
                additionalMatches: {
                        startsWith: ["/prayer/"],
                },
        },
        {
                id: "houses",
                name: "Houses",
                target: "/houses",
                additionalMatches: {
                        startsWith: ["/house/"],
                },
        },
        {
                id: "diary",
                name: "Diary",
                target: "/diary",
        },
];

function isLinkActive(link, path) {
        console.log(link, path);
        if (link.target === path) return true;
        if (link.additionalMatches && link.additionalMatches.startsWith)
                for (let target of link.additionalMatches.startsWith) {
                        if (path.startsWith(target)) return true;
                }
        if (link.additionalMatches && link.additionalMatches.exact)
                for (let target of link.additionalMatches.exact) {
                        if (path === target) return true;
                }
        return false;
}

export default function NavigationBar() {
        let { pathname } = useLocation();

        return (
                <nav className={styles.main}>
                        {links.map((link) => (
                                <Link
                                        className={styles.item}
                                        data-active={isLinkActive(
                                                link,
                                                pathname
                                        )}
                                        to={link.target}
                                        key={link.id}
                                >
                                        {link.name}
                                </Link>
                        ))}
                </nav>
        );
}
