import WidgetContainer from "components/widgets/WidgetContainer";
import PrayerWidget from "components/widgets/PrayerWidget";

export default function Prayers() {
        return (
                <>
                        <h2>Prayers</h2>
                        <WidgetContainer height="25em" width="100%">
                                <PrayerWidget mode="list" animations="off" />
                        </WidgetContainer>
                </>
        );
}
