import styles from "./styles.module.scss";
import { FALSE } from "lib/constants";
import houses from "lib/data/houses";

import { Link } from "react-router-dom";

export default function HouseWidget({ mode = "grid", animations = "true" }) {
        let classes = ["house-widget", styles.widget];
        if (mode === "grid") {
                classes.push(styles.widgetGrid);
        } else {
                classes.push(styles.widgetList);
        }
        if (FALSE.is(animations)) {
                classes.push(styles.widgetNoAnimate);
        }
        return (
                <div className={classes.join(" ")}>
                        {Object.keys(houses).map((houseId) => {
                                let house = houses[houseId];
                                return (
                                        <div
                                                className={`house-container ${styles.houseContainer}`}
                                                title={house.name}
                                                key={houseId}
                                                to={`/house/${houseId}`}
                                        >
                                                <div
                                                        className={`house ${styles.house}`}
                                                        style={{
                                                                "--HouseWidget-bg":
                                                                        house.color,
                                                        }}
                                                ></div>
                                                {(() => {
                                                        if (mode === "list") {
                                                                return (
                                                                        <span>
                                                                                {
                                                                                        house.name
                                                                                }
                                                                        </span>
                                                                );
                                                        }
                                                })()}
                                        </div>
                                );
                        })}
                </div>
        );
}
