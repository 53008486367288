import React from "react";
import "shared-css/core.css";
import { Outlet as RouterOutlet } from "react-router-dom";
import NavigationBar from "components/navigation/NavigationBar";

export default function App() {
        return (
                <>
                        <NavigationBar />
                        <main>
                                <RouterOutlet />
                        </main>
                </>
        );
}
