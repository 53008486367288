const houses = {
        aquinas: {
                name: "Aquinas",
                color: "#66b76e",
        },
        bakhita: {
                name: "Bakhita",
                color: "#5d2c61",
        },
        chanel: {
                name: "Chanel",
                color: "#3b5ba7",
        },
        loyola: {
                name: "Loyola",
                color: "#e7cf07",
        },
        mackillop: {
                name: "MacKillop",
                color: "#743512",
        },
        mcauley: {
                name: "McAuley",
                color: "#14a3c8",
        },
        teresa: {
                name: "Teresa",
                color: "#cdcdca",
        },
        xavier: {
                name: "Xavier",
                color: "#c32324",
        },
};

export default houses;
