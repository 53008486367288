import styles from "./styles.module.scss";
import richtext from "shared-css/richtext.module.scss";
// import { FALSE } from "lib/constants";
import prayers from "lib/data/prayers";

import { Link } from "react-router-dom";

export default function PrayerWidget({ mode = "grid", animations = "true" }) {
        let classes = ["prayers-widget", styles.widget];
        // if (mode === "grid") {
        //         classes.push(styles.widgetGrid);
        // } else {
        //         classes.push(styles.widgetList);
        // }
        // if (FALSE.is(animations)) {
        //         classes.push(styles.widgetNoAnimate);
        // }
        return (
                <div className={classes.join(" ")}>
                        {Object.keys(prayers).map((prayerId) => {
                                let prayer = prayers[prayerId];
                                return (
                                        <>
                                                <Link
                                                        className={`prayer-container ${styles.houseContainer}`}
                                                        title={prayer.name}
                                                        key={prayerId}
                                                        to={`/prayer/${prayerId}`}
                                                >
                                                        <h3>{prayer.name}</h3>
                                                </Link>
                                                <div
                                                        className={`prayer-content rich-text ${richtext.main}`}
                                                        dangerouslySetInnerHTML={{
                                                                __html: prayer.richContent,
                                                        }}
                                                ></div>
                                        </>
                                );
                        })}
                </div>
        );
}
