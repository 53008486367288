import { useParams } from "react-router-dom";
import prayers from "lib/data/prayers";

import richtext from "shared-css/richtext.module.scss";

export default function Prayer() {
        let { prayerId } = useParams();
        if (!prayers.hasOwnProperty(prayerId)) {
                return <em>Prayer not found (temp - replace in prod)</em>;
        }

        let prayer = prayers[prayerId];

        return (
                <>
                        <h2>
                                Prayer: <em>{prayer.name}</em>
                        </h2>

                        <div
                                className={`prayer-content rich-text ${richtext.main}`}
                                dangerouslySetInnerHTML={{
                                        __html: prayer.richContent,
                                }}
                        ></div>
                </>
        );
}
