import "./diary.css";

let data = ["test", "test", "test", "test", "test", "test", "test", "test"];

export default function Home() {
        return (
                <div class="diary">
                        <ul class="sidebar">
                                {data.map((item) => {
                                        return (
                                                <li class="sidebar-item">
                                                        {item}
                                                </li>
                                        );
                                })}
                        </ul>
                        <div class="content">main</div>
                </div>
        );
}
