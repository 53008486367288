import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "./App";

import Home from "routes/home";
import Prayer from "routes/prayer";
import Prayers from "routes/prayers";
import House from "routes/house";
import Houses from "routes/houses";

import Diary from "routes/diary";

var Root = document.createElement("div");
Root.id = "root";
document.body.appendChild(Root);
ReactDOM.render(
        <React.StrictMode>
                <BrowserRouter>
                        <Routes>
                                <Route path="/" element={<App />}>
                                        <Route
                                                path=""
                                                element={
                                                        <Navigate to="/home" />
                                                }
                                        />
                                        <Route path="home" element={<Home />} />
                                        <Route
                                                path="prayers"
                                                element={<Prayers />}
                                        />
                                        <Route
                                                path="diary"
                                                element={<Diary />}
                                        />
                                        <Route
                                                path="prayer"
                                                element={
                                                        <Navigate to="/prayers" />
                                                }
                                        />
                                        <Route
                                                path="prayer/:prayerId"
                                                element={<Prayer />}
                                        />
                                        <Route
                                                path="houses"
                                                element={<Houses />}
                                        />
                                        <Route
                                                path="house"
                                                element={
                                                        <Navigate to="/houses" />
                                                }
                                        />
                                        <Route
                                                path="house/:houseId"
                                                element={<House />}
                                        />
                                </Route>
                        </Routes>
                </BrowserRouter>
        </React.StrictMode>,
        Root
);
