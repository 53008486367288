import WidgetContainer from "components/widgets/WidgetContainer";
import HouseWidget from "components/widgets/HouseWidget";

export default function Home() {
        return (
                <>
                        <h2>
                                Home (with <code>HouseWidget</code>)
                        </h2>

                        <div
                                style={{
                                        gap: "1em",
                                        display: "flex",
                                }}
                        >
                                <WidgetContainer>
                                        <HouseWidget
                                                height="29em"
                                                animations="off"
                                        />
                                </WidgetContainer>
                                <WidgetContainer>
                                        <HouseWidget
                                                mode="list"
                                                animations="off"
                                        />
                                </WidgetContainer>
                        </div>
                </>
        );
}
